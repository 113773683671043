import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';

import { backendFullUrl } from '../services/urls'
import {i18n} from "../translate/i18n";


const baseUrl = backendFullUrl;

class EditComponent extends React.Component{

 constructor(props){
   super(props);
   this.state = {
      laneId: "",
      ticketId: "",
      title:"",
      description:"",
      label:""
   }
 } 

 render(){
  return (
    <div>
        <div className="form-row justify-content-center">
          <div className="form-group col-md-3">
            <label htmlFor="inputPassword4">Lane ID</label>
            <input type="text" className="form-control"  placeholder="Lane ID"
              value={this.state.laneId} onChange={(value)=> this.setState({laneId:value.target.value})}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Ticket ID</label>
            <input type="text" className="form-control"  placeholder="Ticket ID"
              value={this.state.ticketId} onChange={(value)=> this.setState({ticketId:value.target.value})}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Título"
              value={this.state.title} onChange={(value)=> this.setState({title:value.target.value})}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Rótulo</label>
            <input type="text" className="form-control"  placeholder="Rótulo"
              value={this.state.label} onChange={(value)=> this.setState({label:value.target.value})}/>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4">Descrição</label>
            <textarea 
              className="form-control"
              name="description" 
              cols="40" 
              rows="5"
              value={this.state.description} 
              onChange={(value)=> this.setState({description:value.target.value})}
              required="required"
              placeholder="Olá, tudo bem?&#13;&#10;Como posso te ajudar?&#13;&#10;Abraços, a gente se vê!"
            ></textarea>
            {/* <input type="text" className="form-control"  placeholder="Descrição"
              value={this.state.description} onChange={(value)=> this.setState({description:value.target.value})}/> */}
          </div>
        </div>
      <button type="submit" className="btn btn-primary" onClick={()=>this.sendSave()}>{i18n.t('buttons.save')}</button>
      {'   '}
      <button type="submit" className="btn btn-primary" onClick={()=>this.handleClick()}>{i18n.t('lists.listCards')}</button>
    </div>
  );
}

handleClick () {
  this.props.history.push("/listCard");
}

sendSave(){

  if (this.state.laneId==="") {
    alert("O campo lane ID não pode estar vazio.")
  }
  else if (this.state.ticketId==="") {
     alert("O campo ticket ID não pode estar vazio.")
  }
  else if (this.state.title==="") {
    alert("O campo título não pode estar vazio.")
  }
  else if (this.state.description==="") {
    alert("O campo descrição não pode estar vazio.")
  }
  else if (this.state.campLabel==="") {
    alert("O campo rótulo não pode estar vazio.")
  }
  else {

    const datapost = {
      laneId : 'lane' + this.state.laneId,
      ticketId: this.state.ticketId,
      title : this.state.title,
      description : this.state.description,
      label : this.state.label
    }

    axios.post(baseUrl + "/cards/create",datapost)
    .then(response=>{
      if (response.data.success===true) {
        alert(response.data.message)
      }
      else {
        alert(response.data.message)
      }
    }).catch(error=>{
      alert("Error 34 "+ error)
    })

  }

}

}


export default EditComponent;