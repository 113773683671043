const messages = {
  pt: {
    translations: {

      buttons: {
        edit: "Editar",
        delete: "Deletar",
        save: "Salvar",
        send: "Enviar",
        export: "Exportar",
        import: "Importar",
        update: "Atualizar",
      },

      table: {
        id: "ID",
        name: "Nome",
        status: "Status",
        user: "Usuário",
        users: "Usuários",
        label: "Rótulo",
        title: "Título"
      },

      home: {
        limit: "Limite",
        questions: "Perguntas",
        schedules: "Agendamentos",
        groups: "Grupos",
        protocols: "Protocolos",
        timeSlots: "Horários",
        dialogFlow: "DialogFlow",
        chatBot: "ChatBot",
        n8n: "N8N",
        chatGPT: "ChatGPT",
        tags: "Tags",
        kanban: "Kanban"
      },

      lists: {
        listCards: "Listar Cards",
        listControls: "Listar Controles",
        listLanes: "Listar Lanes",
        listQuestions: "Listar Perguntas",
        listSchedules: "Listar Agendamentos",
        listLimits: "Listar Limites",
      },

      list: {
        addQuestion: "Adicionar Pergunta",
        importQuestions: "Importar Perguntas (.csv)",
        edit: "Editar",
        delete: "Deletar",
        question: "Pergunta",
        answer: "Resposta",
        bulkImportQuestions: "Importar Perguntas em Massa",
        csvModel: "Modelo CSV (separado por ;)",
        csvModelEX1: "pergunta;resposta",
        csvModelEX2: "oi, tudo bem?;tudo bem!",
        csvModelEX3: "olá!;oi, tudo certo por aí?",
        csvModelEX4: "mensagem;destinatario;dataEnvio;horario",
        csvModelEX5: "Como vai você?;553588754197;01122022;10:00:00",
        csvModelEX6: "Tudo bem com você?;553588754197;31072022;11:55:00",
      },

      listTimeSlot: {
        start: "Início",
        end: "Término",
        edit: "Editar",
        delete: "Deletar",
        addSingleTimeSlot: "Adicionar apenas uma opção de horário de atendimento",
        addTimeSlot: "Adicionar Horário",
        editTimeSlot: "Editar",
        deleteTimeSlot: "Deletar"
      },

      listSchedule: {
        on: "Ligar",
        off: "Desligar",
        availableConnections: "Conexões Disponíveis",
        addSchedule: "Adicionar Agendamento",
        importSchedules: "Importar Agendamentos (.csv)",
        message: "Mensagem",
        recipient: "Destinatário",
        sendDate: "Data de Envio",
        sendTime: "Horário de Envio",
        sendStatus: "Status de Envio",
        edit: "Editar",
        delete: "Deletar",
        addOnlyController: "Adicionar Apenas Controlador",
        addControl: "Adicionar Controle",
        addGroupControl: "Adicionar Controle de Grupos",
        importBulkSchedules: "Importar Agendamentos em Massa",
      },

      listGroup: {
        status: "Status",
        addOnlyController: "Adicione apenas controlador",
        addControl: "Adicionar Controle"
      },

      listProtocol: {
        protocol: "Protocolo",
        user: "Usuário",
        date: "Data"
      },

      listLimit: {
        user: "Usuário",
        addSingleLimit: "Adicione apenas um limite único de usuários e conexões",
        addLimit: "Adicionar Limite"
      },

      listDialog: {
        manualControlScreen: "Tela de Controle Manual do Chatbot via DialogFlow",
        status: "Status",
        user: "Usuário"
      },

      listChatBot: {
        manualControlScreen: "Tela de Controle Manual do Chatbot via MySQL",
      },

      listN8N: {
        manualControlScreen: "Tela de Controle Manual do Chatbot via N8N",
      },

      listChatGPT: {
        manualControlScreen: "Tela de Controle Manual do Chatbot via ChatGPT"
      },

      listTags: {
        addTag: "Adicionar Tag",
        sendTextTag: "Enviar Tag de Texto",
        sendMediaTag: "Enviar Tag de Mídia",
        sendRecordedAudioTag: "Enviar Tag de Áudio Gravado",
        scheduleTag: "Agendar Tag",
        filterTags: "Filtrar Tags",
        tag: "Tag",
        color: "Cor",
        tagName: "Nome da Tag",
        connectionId: "ID da Conexão",
      },

      listKanban: {
        lanes: "Pistas",
        cards: "Cartões"
      },

      listCards: {
        addCard: "Adicionar Cartão",
        goBack: "Voltar",
        laneId: "ID da Pista",
        ticketId: "ID do Ticket",
        title: "Título",
        description: "Descrição",
        label: "Rótulo",
      },

      listLanes: {
        addLane: "Adicionar Pista",
        goBack: "Voltar",
        laneId: "ID da Pista"
      },

      listTagSchedule: {
        schedule: "Agendar",
        listTags: "Listar Tags"
      },

      listMediaTags: {
        listTags: "Listar Tags",
        audioUrlOGG: "Url Áudio OGG",
        minimum: "Mínimo",
        maximum: "Máximo",
        mediaUrl: "Url Mídia",
      },

      listWhatsApp: {
        manualConnectionControlScreen: "Tela de Controle Manual da Conexão para Envio de Agendamentos",
        listSchedules: "Listar Agendamentos",
      },

      validation: {
        laneIdNotEmpty: "O campo lane ID não pode estar vazio.",
        titleNotEmpty: "O campo título não pode estar vazio.",
        labelNotEmpty: "O campo rótulo não pode estar vazio.",
        startNotEmpty: "O campo início não pode estar vazio.",
        endNotEmpty: "O campo término não pode estar vazio.",
        statusNotEmpty: "O campo status não pode estar vazio.",
        questionNotEmpty: "O campo pergunta não pode estar vazio.",
        answerNotEmpty: "O campo resposta não pode estar vazio.",
        userNotEmpty: "O campo usuário não pode estar vazio.",
        messageNotEmpty: "O campo mensagem não pode estar vazio.",
        tagNotEmpty: "O campo tag não pode estar vazio.",
        tokenNotEmpty: "O campo token não pode estar vazio.",
        minimumNotEmpty: "O campo mínimo não pode estar vazio.",
        maximumNotEmpty: "O campo máximo não pode estar vazio.",
        dispatchStarted: "Disparo iniciado! Clique em OK e aguarde a conclusão do envio.",
        dateNotEmpty: "O campo data não pode estar vazio.",
        timeNotEmpty: "O campo horário não pode estar vazio.",
        audioUrlNotEmpty: "O campo URL do áudio não pode estar vazio.",
        minNotEmpty: "O campo mínimo não pode estar vazio.",
        maxNotEmpty: "O campo máximo não pode estar vazio.",
        mediaNotEmpty: "O campo mídia não pode estar vazio.",
        colorNotEmpty: "O campo color não pode estar vazio.",
        tagAlreadyExists: "Uma tag com esse nome já existe.",
      },

      hints: {
        question: "Olá, tudo bem?\r\nComo posso te ajudar?\r\nAbraços, a gente se vê!",
        answer: "Olá, tudo bem?\r\nComo posso te ajudar?\r\nAbraços, até logo!",
      },

    },
  },
};

export { messages };
