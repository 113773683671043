import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';

import {CSVLink} from 'react-csv';

import { backendFullUrl } from '../services/urls'
import {i18n} from "../translate/i18n";

const baseUrl = backendFullUrl;

class ListTagUser extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      tags: []
    }
  } 

  componentDidMount() {
    this.loadTagList();
  }

  loadTag(tagChoose){
    axios.get(baseUrl + "/taguser/list")
    .then(res => {
      if(res.data.success){
        const users = res.data.data;
        let tagSelected = []
        for (const user of users) {
          if(user.tag.includes(tagChoose) ){
            tagSelected.push(user.usuario + ' ')
          }
        }
        this.setState({ numberUser: tagSelected });
      }
      else{
          alert("Error web service");
      }
    })
    .catch(error => {
      alert("Error server " + error)
    });
  }

  loadTagList(){
    axios.get(baseUrl + "/tag/list")
    .then(res => {
      if(res.data.success){
        const data = res.data.data;
        this.setState({ tags: data });
      }
      else{
          alert("Error web service");
      }
    })
    .catch(error => {
      alert("Error server " + error)
    });
  }

  handleChange = (event) => {
    this.setState({ tagUser: event.target.value });
    this.loadTag(event.target.value);
  };

  handleClick () {
    this.props.history.push("/listTag");
  }

  render(){
      const { tagUser } = this.state;
      return <div className="drop-down">
        <div>
          <div className="form-row justify-content-center">
            <div className="form-group col-md-12">
            <p>{i18n.t('listTags.tag')}: {this.state.tagUser} {<br></br>} {i18n.t('table.users')}: {this.state.numberUser}</p>
              <select
                  className="form-control"
                  onChange={this.handleChange} 
                  value={tagUser} 
                >
                <option value="none" selected disabled hidden> 
                </option>
                {
                this.state.tags.map((tag) => {
                    return <option style={{ backgroundColor: tag.color }} value={tag.tag} key={tag.id}>{tag.tag}</option>
                })
              }</select>
            </div>
          </div>
          <button type="submit" className="btn btn-primary" onClick={()=>this.handleClick()}>{i18n.t('listMediaTags.listTags')}</button>
          {'   '}
          {(this.state.numberUser !== undefined) &&
          <CSVLink style={{ textDecoration:'none'}} separator=";" filename={tagUser + '_zdg.csv'} data={this.state.numberUser.toString().replaceAll(',','').split(' ').map((user) => ({usuario: user}))}> 
          <button type="submit" className="btn btn-primary">{i18n.t('buttons.export')}</button>
          </CSVLink>
          }
        </div>
      </div>;
  }
}

export default ListTagUser;