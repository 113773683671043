const messages = {
  es: {
    translations: {

      home: {
      }
    },
  },
};

export { messages };
