import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import {i18n} from '../translate/i18n';

import { backendFullUrl } from '../services/urls'

const baseUrl = backendFullUrl;

class EditComponent extends React.Component{

 constructor(props){
   super(props);
   this.state = {
      laneId: "",
      title:"",
      campLabel:""
   }
 } 

 render(){
  return (
    <div>
      <div className="form-row justify-content-center">
        <div className="form-group col-md-4">
            <label htmlFor="inputPassword4">Lane ID</label>
            <input type="text" className="form-control"  placeholder="Lane ID"
              value={this.state.laneId} onChange={(value)=> this.setState({laneId:value.target.value})}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Título"
              value={this.state.title} onChange={(value)=> this.setState({title:value.target.value})}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Rótulo"
              value={this.state.campLabel} onChange={(value)=> this.setState({campLabel:value.target.value})}/>
          </div>
      </div>
      <button type="submit" className="btn btn-primary" onClick={()=>this.sendSave()}>{i18n.t('buttons.save')}</button>
      {'   '}
      <button type="submit" className="btn btn-primary" onClick={()=>this.handleClick()}>{i18n.t('lists.listLanes')}</button>
    </div>
  );
}

handleClick () {
  this.props.history.push("/listLane");
}

sendSave(){

  if (this.state.laneId==="") {
    alert(i18n.t('validation.laneIdNotEmpty'))
  }
  else if (this.state.title==="") {
     alert(i18n.t('validation.titleNotEmpty'))
  }
  else if (this.state.campLabel==="") {
    alert(i18n.t('validation.labelNotEmpty'))
  }
  else {

    const datapost = {
      laneId : 'lane' + this.state.laneId,
      title : this.state.title,
      label : this.state.campLabel
    }

    axios.post(baseUrl + "/lanes/create",datapost)
    .then(response=>{
      if (response.data.success===true) {
        alert(response.data.message)
      }
      else {
        alert(response.data.message)
      }
    }).catch(error=>{
      alert("Error 34 "+ error)
    })

  }

}

}


export default EditComponent;