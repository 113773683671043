const messages = {
  en: {
    translations: {

      buttons: {
        edit: "Edit",
        delete: "Delete",
        save: "Save",
        send: "Send",
        export: "Export",
        import: "Import",
        update: "Update",
      },

      table: {
        id: "ID",
        name: "Name",
        status: "Status",
        user: "User",
        users: "Users",
        label: "Label",
        title: "Title"
      },

      home: {
        limit: "Limit",
        questions: "Questions",
        schedules: "Schedules",
        groups: "Groups",
        protocols: "Protocols",
        timeSlots: "Time Slots",
        dialogFlow: "DialogFlow",
        chatBot: "ChatBot",
        n8n: "N8N",
        chatGPT: "ChatGPT",
        tags: "Tags",
        kanban: "Kanban",
      },

      lists: {
        listCards: "List Cards",
        listControls: "List Controls",
        listLanes: "List Lanes",
        listQuestions: "List Questions",
        listSchedules: "List Schedules",
        listLimits: "List Limits",
      },

      list: {
        addQuestion: "Add Question",
        importQuestions: "Import Questions (.csv)",
        edit: "Edit",
        delete: "Delete",
        question: "Question",
        answer: "Answer",
        bulkImportQuestions: "Bulk Import Questions",
        csvModel: "CSV Model (separated by ;)",
        csvModelEX1: "Question;Answer",
        csvModelEX2: "hi, how are you?;how are you?",
        csvModelEX3: "hello!;hi everything alright there?",
        csvModelEX4: "message; recipient; shipping date; time",
        csvModelEX5: "How are you?;553588754197;01122022;10:00:00",
        csvModelEX6: "How are you?;553588754197;31072022;11:55:00",
      },

      listTimeSlot: {
        start: "Start",
        end: "End",
        edit: "Edit",
        delete: "Delete",
        addSingleTimeSlot: "Add Single Time Slot",
        addTimeSlot: "Add Time Slot",
        editTimeSlot: "Edit",
        deleteTimeSlot: "Delete"
      },

      listSchedule: {
        on: "On",
        off: "Off",
        availableConnections: "Available Connections",
        addSchedule: "Add Schedule",
        importSchedules: "Import Schedules (.csv)",
        message: "Message",
        recipient: "Recipient",
        sendDate: "Send Date",
        sendTime: "Send Time",
        sendStatus: "Send Status",
        edit: "Edit",
        delete: "Delete",
        addOnlyController: "Add Only Controller",
        addControl: "Add Control",
        addGroupControl: "Add Group Control",
        importBulkSchedules: "Import Bulk Schedules",
      },

      listGroup: {
        status: "Status",
        addOnlyController: "Add Only Controller",
        addControl: "Add Control"
      },

      listProtocol: {
        protocol: "Protocol",
        user: "User",
        date: "Date"
      },

      listLimit: {
        user: "User",
        addSingleLimit: "Add only a single limit of users and connections",
        addLimit: "Add Limit"
      },

      listDialog: {
        manualControlScreen: "Manual Control Screen of Chatbot via DialogFlow",
        status: "Status",
        user: "User"
      },

      listChatBot: {
        manualControlScreen: "Manual Control Screen of Chatbot via MySQL",
      },

      listN8N: {
        manualControlScreen: "Manual Control Screen of Chatbot via N8N",
      },

      listChatGPT: {
        manualControlScreen: "Manual Control Chatbot Screen via ChatGPT"
      },

      listTags: {
        addTag: "Add Tag",
        sendTextTag: "Send Text Tag",
        sendMediaTag: "Send Media Tag",
        sendRecordedAudioTag: "Send Recorded Audio Tag",
        scheduleTag: "Schedule Tag",
        filterTags: "Filter Tags",
        tag: "Tag",
        color: "Color",
        tagName: "Tag Name",
        connectionId: "Connection ID",
      },

      listKanban: {
        lanes: "Lanes",
        cards: "Cards"
      },

      listCards: {
        addCard: "Add Card",
        goBack: "Go Back",
        laneId: "Lane ID",
        ticketId: "Ticket ID",
        title: "Title",
        description: "Description",
        label: "Label"
      },

      listLanes: {
        addLane: "Add Lane",
        goBack: "Go Back",
        laneId: "Lane ID"
      },

      listTagSchedule: {
        schedule: "Schedule",
        listTags: "List Tags"
      },

      listMediaTags: {
        listTags: "List Tags",
        audioUrlOGG: "Audio URL (OGG)",
        minimum: "Minimum",
        maximum: "Maximum",
        mediaUrl: "Media URL",
      },

      listWhatsApp: {
        manualConnectionControlScreen: "Manual Connection Control Screen for Schedule Sending",
        listSchedules: "List Schedules",
      },

      validation: {
        laneIdNotEmpty: "Lane ID cannot be empty.",
        titleNotEmpty: "Title cannot be empty.",
        labelNotEmpty: "Label cannot be empty.",
        startNotEmpty: "Start field cannot be empty.",
        endNotEmpty: "End field cannot be empty.",
        statusNotEmpty: "Status field cannot be empty.",
        questionNotEmpty: "Question field cannot be empty.",
        answerNotEmpty: "Answer field cannot be empty.",
        userNotEmpty: "User field cannot be empty.",
        messageNotEmpty: "The message field cannot be empty.",
        tagNotEmpty: "The tag field cannot be empty.",
        tokenNotEmpty: "The token field cannot be empty.",
        minimumNotEmpty: "The minimum field cannot be empty.",
        maximumNotEmpty: "The maximum field cannot be empty.",
        dispatchStarted: "Dispatch started! Click OK and wait for the sending process to complete.",
        dateNotEmpty: "The date field cannot be empty.",
        timeNotEmpty: "The time field cannot be empty.",
        audioUrlNotEmpty: "The audio URL field cannot be empty.",
        minNotEmpty: "The minimum field cannot be empty.",
        maxNotEmpty: "The maximum field cannot be empty.",
        mediaNotEmpty: "The media field cannot be empty.",
        colorNotEmpty: "The color field cannot be empty.",
        tagAlreadyExists: "A tag with this name already exists.",
      },

      hints: {
        question: "Hello, how can I assist you?\r\nBest regards, see you!",
        answer: "Hello, how can I help you?\r\nBest regards, see you!",
      },

    },
  },
};

export { messages };
